.ant-btn {
  //text-transform: uppercase;
  height: 38px;
  letter-spacing: 0.05em;
  font-size: 13px;
  border-radius: 2px;
  transition: none;

  // .anticon {
  //     vertical-align: text-top;
  //     svg {
  //         color: $wht;
  //     }
  // }
  &:hover,
  &:focus {
    color: #fff;
    border-color: #a1b8d0;
  }
  &:active {
    background: inherit;
  }
  span {
    font-weight: 700;
  }

  &.ant-btn-sm {
    width: 62px;
  }
}

.ant-btn-primary {
  // @include btn($primary-color, $wht);
  //background-color: $c-primary;
  //border-color: $c-primary;
  border: 2px solid #bda18c;

  &:hover,
  &:focus {
    //background-color: lighten($c-primary,3);
    //border-color: lighten($c-primary,3);
    color: #fff;
    opacity: 0.8;
    background: #bda18c;
    border: 2px solid #bda18c;
    cursor: pointer;
  }
}

.ant-btn-primary {
  .anticon {
    svg {
      color: $wht;
    }
  }
}

.ant-btn-secondary {
  .anticon {
    svg {
      color: $k100;
    }
  }
}

.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  color: #fff;
  opacity: 0.8;
  background: #bda18c;
  border: 2px solid #bda18c;
}

.ant-btn-danger {
  background-color: $danger;
  color: #fff;
  border-color: transparent;

  &:focus {
    color: #fff;
    background-color: darken($danger, 5);
    border-color: transparent;
  }
}

.importbutton:hover {
  opacity: 0.8;
}
