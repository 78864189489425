.four0for-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  text-align: center;

  h1 {
    font-size: 9em;
    // color:#001529;
    color: #2c2738;
  }

  h2 {
    margin-bottom: 1em;
  }
}

.table-first-row {
  padding: 10px;
  float: right;
}
.table-first-row button {
  min-height: 50px;
  min-width: 150px;
  font-size: 20px;
}
